<template>
  <a-modal v-if="visible" width="80%" @ok="handleOk" title="物模型" :visible="visible" @cancel="closeModal">
    <a-transfer
      :data-source="tableData"
      :target-keys="targetKeys"
      show-search
      :filter-option="filterOption"
      :show-select-all="false"
      @change="onChange"
      :search-placeholder="'请输入产品名称'"
    >
      <template
        slot="children"
        slot-scope="{
          props: { direction, filteredItems, selectedKeys, disabled: listDisabled },
          on: { itemSelectAll, itemSelect },
        }"
      >
        <a-table
          :loading="tableLoading"
          :row-selection="getRowSelection({ disabled: listDisabled, selectedKeys, itemSelectAll, itemSelect })"
          :columns="columns"
          :data-source="filteredItems"
          size="small"
        >
          <template slot="type" slot-scope="scope">
            {{ productTypes(scope) }}
          </template>
        </a-table>
      </template>
    </a-transfer>
  </a-modal>
</template>

<script>
import difference from 'lodash/difference';

export default {
  name: 'ProductTransferModal',
  data() {
    return {
      tableData: [],
      tableLoading: false,
      visible: false,
      targetKeys: [],
      metaData: {},
      columns: [
        {
          dataIndex: 'productId',
          title: '产品ID',
        },
        {
          dataIndex: 'name',
          title: '产品名称',
        },
        {
          dataIndex: 'type',
          title: '产品类型',
          scopedSlots: { customRender: 'type' },
          filterMultiple: false,
          onFilter: (value, record) => record.type === value,
          filters: [
            { text: '父设备', value: 1 },
            { text: '子设备', value: 2 },
          ],
        },
      ],
    };
  },
  methods: {
    openModal(metaData, selectedList) {
      this.listProducts();
      this.metaData = metaData;
      this.targetKeys = selectedList.map((item) => item.productId);
      this.visible = true;
    },
    closeModal() {
      this.visible = false;
      this.tableData = [];
      this.targetKeys = [];
    },
    handleOk() {
      const targetProduct = this.tableData.filter((product) => this.targetKeys.includes(product.productId));
      this.$emit('selectedProduct', targetProduct);
      this.closeModal();
    },
    onChange(nextTargetKeys) {
      this.targetKeys = nextTargetKeys;
    },
    listProducts() {
      this.tableLoading = true;
      const params = { unpaged: true };
      this.$apiManager.product
        .getProducts(params)
        .then((response) => {
          this.tableData = response.data.elements.map(({ productId, name, type }) => ({
            key: productId,
            productId,
            name,
            type,
          }));
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    getRowSelection({
 disabled, selectedKeys, itemSelectAll, itemSelect,
}) {
      return {
        getCheckboxProps: (item) => ({ props: { disabled: disabled || item.disabled } }),
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows.map(({ key }) => key);
          const diffKeys = selected
            ? difference(treeSelectedKeys, selectedKeys)
            : difference(selectedKeys, treeSelectedKeys);
          itemSelectAll(diffKeys, selected);
        },
        onSelect({ key }, selected) {
          itemSelect(key, selected);
        },
        selectedRowKeys: selectedKeys,
      };
    },
    filterOption(inputValue, item) {
      return item.name.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
    },
  },
  computed: {
    productTypes() {
      return (type) => {
        const typeName = this.metaData.productTypes.find((md) => md.value === type);
        return typeName ? typeName.name : '';
      };
    },
  },
};
</script>
